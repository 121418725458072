import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import Text from "../Text"

const Wrapper = styled.div`
  width: 100%;
  height: 87px;
  padding: 0 130px;
  background: transparent;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Nav = styled.div`
  width: 261px;
  display: flex;
  justify-content: space-between;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const Header = () => (
  <Wrapper>
    <Nav>
      <StyledLink to="/">
        <Text>Work</Text>
      </StyledLink>
      <StyledLink to="/">
        <Text>About</Text>
      </StyledLink>
      <StyledLink to="/">
        <Text>Contact</Text>
      </StyledLink>
    </Nav>
  </Wrapper>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
