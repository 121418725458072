import styled from "styled-components"
import React from "react"
import breakpoint from "styled-components-breakpoint"

const Wrapper = styled.div`
  min-height: 10px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: ${props => props.shadow && "0 0 2px 0 rgba(0,0,0,0.2)"};
  a {
    text-decoration: none;
  }
`

const ChildWrapper = styled.div`
  margin: ${props => (props.header ? "20px auto" : "0 auto")};
  text-align: ${props => props.center && "center"};
  ${breakpoint("mobile")`
    width: 350px;
  `}
  ${breakpoint("tablet")`
    width: 700px;
  `}
  ${breakpoint("desktop")`
    width: 1230px;
  `}
`

export default props => (
  <Wrapper {...props}>
    <ChildWrapper {...props}>{props.children}</ChildWrapper>
  </Wrapper>
)
