import styled from "styled-components"
import React from "react"

const Wrapper = styled.div`
  height: 80px;
  width: 80px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 130px;
`
const Logo = () => (
  <Wrapper>
    <img src={require("../../../static/images/HQ.svg")} alt="HQ Logo" />
  </Wrapper>
)

export default Logo
