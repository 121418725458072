import styled from "styled-components"
import React from "react"

const Typography = styled.div`
  font-family: Maison Neue Extended, sans-serif;
  font-size: 16px;
  ${props => props.small && `font-size: 14px;`}
  ${props => props.jumboLarge && `font-size: 64px; font-weight: bold;`}
  ${props => props.jumboMedium && `font-size: 30px; font-weight: bold;`}
  ${props => props.jumboSmall && `font-size: 20px;`}
`
const Text = props => {
  return <Typography {...props}>{props.children}</Typography>
}

export default Text
