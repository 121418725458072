import styled from "styled-components"
import React from "react"

const Wrapper = styled.div`
  padding: 60px;
  width: 100%;
  p, a, h1, h3 {
    font-family: 'Roboto Mono', monospace;
    color: white;
  }
  p {
    line-height: 26px;
  }
  h3 {
    font-family: 'Roboto Mono', monospace;
  }
  a {
    color: #ff3864;
    text-decoration: none;
    &:visited {
      text-decoration: none;
    }
  }
`
const Info = styled.div`
  padding: 30px;
`

const Tech = styled.div`
  padding: 30px;
`

const ExperienceWrapper = styled.div`
  padding: 30px;
  .margin {
    margin-bottom: 60px;
  }
`

const Experience = styled.div`
  margin-bottom: 30px;
  a {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
`

const Education = styled.div`
  padding: 30px;
`

//TODO: Find best way to handle scroll animation, and then finish styling
export default props => (
  <Wrapper {...props}>
    <Info>
      <p>Tyler Rex Allen</p>
      <p>tylerrexallen@gmail.com</p>
      <p>(385) 244-5104</p>
      <p>500 Broadway</p>
      <p>Farmington UT, 84025</p>
    </Info>

    <Tech>
      <h1>TECH</h1>
      <p>Modern HTML/CSS + pre-processors, Javascript, React (w/ Hooks), Node.js, Gatsby, Git, Contentful, Wordpress, AEM </p>
    </Tech>

    <ExperienceWrapper>
      <h1>DEV WORK EXPERIENCE</h1>
      <h3><a href="https://www.builtbyhq.com">HQ</a> - Digital Agency (2015 - 2019)</h3>
      <h3><a href="https://www.hoodoodigital.com">Hoodoo Digital</a> - Digital Agency (2019 - present)</h3>
      <p>I &lt;3 making rad stuff.</p>
      <p className="margin">I'm a hard worker and love to learn. I have excellent interpersonal skills and will fit right into any team, bringing a good attitude and some fun.</p>
      <p className="margin">Some of the stuff i've gone and done:</p>
      <Experience>
        <a href="https://www.collegewise.com">https://www.collegewise.com</a>
        <p>- Helped build and maintain this website and it's sister sites.</p>
        <p>- Started out Wordpress and was converted to a static site built with Metalsmith.</p>
        <p>- Custom CMS built with Contentful.</p>
      </Experience>
      <Experience>
        <a href="https://www.peakpayment.com/">https://www.peakpayment.com/</a>
        <p>- Co-led a team on building this payment system using React</p>
        <p>- Built reusable styled-components.</p>
      </Experience>
      <Experience>
        <a href="https://www.bsqu.com">https://www.bsqu.com/</a>
        <p>- Fun marketing site built from scratch</p>
        <p>- Simple CMS setup through Contentful using Metalsmith</p>
      </Experience>
      <Experience>
        <a href="https://www.clickfunnels.com/blog/history-of-sales">https://www.clickfunnels.com/blog/history-of-sales</a>
        <p>- Cool parallax stuff using Greensock animation library</p>
      </Experience>
      <Experience>
        <a href="http://www.funeralrecording.com/">http://www.funeralrecording.com/</a>
        <p>- Worked with a team to build a funeral recording webapp in Angular</p>
      </Experience>
      <Experience>
        <a href="https://www.bass-mollett.com/bassdownloads.php">https://www.bass-mollett.com/bassdownloads.php</a>
        <p>- Helped build the Directors Print Suite webapp using Angular and eventually ported it to React.</p>
      </Experience>
      <Experience>
        <a href="https://www.welcomesoftware.com">https://www.welcomesoftware.com</a>
        <p>- Produced a plugin for Adobe Creative Cloud to interact with Welcomes API using React and the <a href="https://www.adobe.io/photoshop/uxp/">Adobe UXP Tools</a></p>
      </Experience>
      <Experience>
        <a href="https://www.viega.us/en/homepage.html">https://www.viega.us/en/homepage.html</a>
        <p>- Helped a small team take Viegas site and port it to AEM.</p>
      </Experience>
      <Experience>
        <a href="https://www.progrexion.com/">https://www.progrexion.com/</a>
        <p>- Helped build a "Logged In Experience" app using AEM with React for users to log in and see detailed information and graphs about their financial status. </p>
        <p>- Did some really fun animations with ReactChart.js</p>
      </Experience>
    </ExperienceWrapper>
    

    <Education>
      <h1>EDUCATION</h1>
      <p>Weber State University</p>
      <p>B.A. in Computer Science 2012-2015</p>
    </Education>

  </Wrapper>
)